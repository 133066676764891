import { useTranslation } from 'react-i18next';
import img1 from '../images/news/1.png';
import img2 from '../images/news/2.png';
import img3 from '../images/news/3.png';
import img4 from '../images/news/4.jpg';
import img5 from '../images/news/5.png';
import img6 from '../images/news/6.jpg';
import img7 from '../images/news/7.png';
import img8 from '../images/news/8.jpg';
import img9 from '../images/news/9.png';
import img10 from '../images/news/10.png';
import img11 from '../images/news/11.png';
import img12 from '../images/news/12.png';
import img14 from '../images/news/14.png';
import img15 from '../images/news/15.png';
import img16 from '../images/news/16.png';
import img17 from '../images/news/17.png';
import img18 from '../images/news/18.png';
import img19 from '../images/news/19.png';

type Link = {
  text: string;
  url: string;
};

type NewsItem = {
  id: number;
  title?: string;
  images?: {
    url: string;
    description?: string;
    backgroundPosition?: Record<string, string>;
  }[];
  paragraphs?: string;
  link?: Link[];
  date?: string;
  textLink?: string;
};

export const useTranslatedNewsData = (): NewsItem[] => {
  const { t } = useTranslation('global');

  return [
    {
      id: 1,
      title: 'news.id_1.title',
      images: [
        { url: img18 },
        {
          url: img15,
        },
        {
          url: img19,
          backgroundPosition: {
            xs: 'right 85% top 55%',
            md: 'right 85% top 70%',
          },
        },
      ],
      paragraphs: 'news.id_1.paragraphs',
      link: [
        {
          text: t('news.id_1.link.event'),
          url: 'https://www.linkedin.com/posts/p5-property-kongress_p5kongress-wahrewerte-kapeuropa-activity-7189857924393947137-E5XP?utm_source=share&utm_medium=member_ios',
        },
      ],
      date: t('news.id_1.date'),
    },
    {
      id: 9,
      title: 'news.id_9.title',
      images: [
        {
          url: img16,
          description: t('news.id_9.images.image_16.description'),
        },
        { url: img17 },
      ],
      paragraphs: 'news.id_9.paragraphs',
      date: t('news.id_9.date'),
    },
    {
      id: 3,
      title: 'news.id_3.title',
      images: [{ url: img1 }],
      paragraphs: 'news.id_3.paragraphs',
      date: t('news.id_3.date'),
    },
    {
      id: 2,
      title: t('news.id_2.title'),
      images: [
        {
          url: img11,
          description: t('news.id_2.images.image_11.description'),
        },
        { url: img12 },
      ],
      paragraphs: 'news.id_2.paragraphs',
      date: t('news.id_2.date'),
      link: [
        {
          text: t('news.id_2.link.event'),
          url: 'https://alphazirkel.de/veranstaltungen/the-future-of-real-estate-market-dynamics-and-innovation/',
        },
      ],
    },
    {
      id: 4,
      title: t('news.id_4.title'),
      images: [
        {
          url: img2,
          description: t('news.id_4.images.image_2.description'),
        },
        { url: img3 },
      ],
      paragraphs: 'news.id_4.paragraphs',
      date: t('news.id_4.date'),
    },
    {
      id: 5,
      title: t('news.id_5.title'),
      images: [{ url: img14 }, { url: img4 }],
      paragraphs: 'news.id_5.paragraphs',
      textLink: 'news.id_5.textLink',
      link: [
        {
          text: 'news.id_5.link.article',
          url: 'https://www.iz.de/finanzen/news/-drittsicherheiten-oeffnen-wege-zu-mehr-liquiditaet-2000023246',
        },
      ],
      date: t('news.id_5.date'),
    },
    {
      id: 6,
      title: t('news.id_6.title'),
      images: [{ url: img5 }],
      paragraphs: 'news.id_6.paragraphs',
      date: t('news.id_6.date'),
    },
    {
      id: 7,
      title: t('news.id_7.title'),
      images: [
        { url: img7 },
        {
          url: img6,
          description: t('news.id_7.images.images_6.description'),
        },
        { url: img8 },
      ],
      paragraphs: 'news.id_7.paragraphs',
      link: [
        {
          text: t('news.id_7.link.0.text'),
          url: 'https://www.frankfurt-school-verlag.de/verlag/konferenz/real_estate_finance_day2023.html',
        },
        {
          text: t('news.id_7.link.1.text'),
          url: 'https://indd.adobe.com/view/publication/dfc37ac8-6020-4eec-8c0a-06ffdebdd555/t5uh/publication-web-resources/pdf/REFD_DAS_MAGAZIN_ZUM_10._REAL_ESTATE_FINANCE_DAY.pdf',
        },
      ],
      date: t('news.id_7.date'),
    },
    {
      id: 8,
      title: t('news.id_8.title'),
      images: [{ url: img9 }, { url: img10 }],
      paragraphs: 'news.id_8.paragraphs',
      textLink: 'news.id_8.textLink',
      link: [
        {
          text: 'news.id_8.link.innovationsradar',
          url: 'https://www.zia-innovationsradar.de/',
        },
      ],
      date: t('news.id_8.date'),
    },
  ];
};
